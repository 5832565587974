<template>
  <div>
    <SpFinder no-selection ref="finder" :setting="setting" row-actions-width="430" :row-actions-align="'left'"
      :fixed-row-action="true" :split-count="6" :url="url" :hooks="{
        beforeSearch: beforeSearch,
        afterSearch: afterSearch
      }">
      <template slot="tableTop" >
        <el-tabs v-model="platform" type="card" @tab-click="handleTabPlatformClick" v-if="admin_type !== 'order'||(login_type === 'disti' && admin_type === 'order')">
          <el-tab-pane label="微信小程序" v-if="admin_type !== 'order'" name="wxapp"></el-tab-pane>
          <el-tab-pane label="抖音小程序" v-if="login_type === 'disti' && admin_type === 'order'"
            name="byte_mini"></el-tab-pane>
        </el-tabs>
        <el-tabs v-model="activeName" type="card" @tab-click="handleTabActiveClick">
          <el-tab-pane :label="`全部(${examine_status_total_count})`" name="all"></el-tab-pane>
          <el-tab-pane :label="`待提交(${examine_status_before_count})`" name="waiting"></el-tab-pane>
          <el-tab-pane :label="`待审核(${examine_status_waiting_count})`" name="examine"></el-tab-pane>
          <el-tab-pane :label="`审核未通过(${examine_status_refuse_count})`" name="refuse"></el-tab-pane>
          <el-tab-pane :label="`审核已通过(${examine_status_pass_count})`" name="pass"></el-tab-pane>
        </el-tabs>
      </template>
    </SpFinder>
    <SpDialog ref="customPageDialogRef" v-model="customPageDialog" :title="customPageTitle" :modal="false"
      :form="customPage" :form-list="customPageList" @onSubmit="onCustomPageSubmit" />
    <SpDialog ref="examineDialogRef" v-model="examineDialog" :title="examineTitle" :modal="false" :form="examineForm"
      :form-list="examineFormList" @onSubmit="handleExamineFormSubmit" />
    <ExamineTemplate @closeDialog="closeExamineDialog" :examineDialogVisible="examineDialogVisible"
      :templateId="selectTemplate?.id" templateType="custompage"></ExamineTemplate>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { createSetting, useDialog } from '@shopex/finder'
import { getCustomPageList, createCustomPage, delCustomPage, editCustomPage } from '@/api/wxa'
import ExamineTemplate from '@/components/function/examineTemplate'
import { EXAMINE_STATUS, EXAMINE_STATUS_TYPE } from '@/consts'
export default {
  components: { ExamineTemplate },
  data() {
    return {
      platform: '',
      customPageTitle: '新增页面',
      url: '',
      customPage: {
        id: '',
        page_name: '',
        page_description: '',
        page_share_title: '',
        page_share_desc: '',
        page_share_imageUrl: '',
        page_imageUrl: '',
        is_open: true,
        sync_avarto: false,
        platform: ''
      },
      customPageList: [
        {
          label: '页面名称',
          key: 'page_name',
          type: 'input',
          maxlength: 30,
          placeholder: '请输入页面名称',
          required: true,
          message: '页面名称不能为空'
        }, {
          label: '使用平台',
          key: 'platform',
          component: ({ key }) => {
            return (
              <div>
                <el-input value={this.customPage[key] == 'wxapp' ? '微信小程序' : '抖音小程序'} disabled={true}></el-input>
              </div>
            )
          },
        }, {
          label: '页面描述',
          key: 'page_description',
          type: 'input',
          maxlength: 150,
          placeholder: '请输入页面描述',
        }, {
          label: '分享标题',
          key: 'page_share_title',
          type: 'input',
          maxlength: 150,
          placeholder: '请输入分享标题',
        }, {
          label: '分享图片',
          key: 'page_share_imageUrl',
          component: ({ key }) => {
            return (
              <div on-click={() => this.handleImgChange()}>
                <SpImage src={this.customPage[key]} class="banner-uploader" />
              </div>
            )
          },
        }, {
          label: '是否启用',
          key: 'is_open',
          type: 'switch',
        }],
      customPageDialog: false,
      examineForm: {
        is_auto_replace: 1,
        cron_replace_time: ''
      },
      examineFormList: [{
        label: '模块更新方式',
        key: 'is_auto_replace',
        component: ({ key }) => {
          return <el-radio-group v-model={this.examineForm[key]} size="small" onChange={(e) => {
            this.$set(this.examineForm, 'cron_replace_time', '')
          }}>
            <el-radio-button label="1">自动更新</el-radio-button>
            <el-radio-button label="2">定时更新</el-radio-button>
          </el-radio-group>
        }
      }, {
        label: '定时更新时间',
        key: 'cron_replace_time',
        isShow: () => {
          return this.examineForm.is_auto_replace == 2
        },
        component: ({ key }) => {
          return <el-date-picker
            v-model={this.examineForm[key]}
            type="datetime"
            picker-options={
              {
                disabledDate(time) {
                  return time.getTime() < Date.now() - 3600 * 1000 * 24;
                }
              }
            }
            value-format='timestamp'
            placeholder="选择日期时间">
          </el-date-picker>
        }
      }],
      examineDialog: false,
      examineTitle: '',
      examine_status_total_count: 0,
      examine_status_waiting_count: 0,
      examine_status_refuse_count: 0,
      examine_status_pass_count: 0,
      examine_status_before_count: 0,
      activeName: 'all',
      selectTemplate: null,
      examineDialogVisible: false,
    }
  },
  methods: {
    beforeSearch(params) {
      const status = {
        'all': '',
        'waiting': '1',
        'examine': '2',
        'refuse': '3',
        'pass': '4'
      }
      params = {
        ...params,
        examine_status: status[this.activeName],
        getAll:true,
        template_name:this.template_name,
        platform:this.platform
      }
      return params
    },
    afterSearch(res) {
      const { data } = res.data
      this.examine_status_before_count = data.examine_status_before_count
      this.examine_status_total_count = data.examine_status_total_count
      this.examine_status_waiting_count = data.examine_status_waiting_count
      this.examine_status_refuse_count = data.examine_status_refuse_count
      this.examine_status_pass_count = data.examine_status_pass_count
      return data
    },
    //切换抖音小程序还是微信小程序
    handleTabPlatformClick() {
      if (this.platform == 'wxapp') {
        this.url = `/wxa/customizepage/list`
      } else {
        this.url = `byte_mini/customizepage/list`
      }
    },
    //切换审核状态
    handleTabActiveClick(tab) {
      this.$refs.finder.refresh(true)
    },
    //选择分享图片
    async handleImgChange() {
      const { data } = await this.$picker.image({
        data: { url: this.customPage.page_share_imageUrl }
      })
      this.$set(this.customPage, 'page_share_imageUrl', data.url)
    },
    //新增/修改页面
    async onCustomPageSubmit() {
      if (this.customPage.id) {
        const { id, ...data } = this.customPage
        await editCustomPage(id, data)
      } else {
        await createCustomPage({ template_name: this.template_name, ...this.customPage })
      }
      this.$message({
        type: 'success',
        message: '保存页面成功'
      })
      this.customPageDialog = false
      this.$refs.finder.refresh(true)
    },
    //新增页面
    handleAddCustomPage() {
      this.customPageDialog = true
      this.customPage = {
        id: '',
        page_name: '',
        page_description: '',
        page_share_title: '',
        page_share_desc: '',
        page_share_imageUrl: '',
        page_imageUrl: '',
        is_open: true,
        sync_avarto: false,
        page_type: '',
        platform: this.platform
      }
      this.customPageTitle = '新增页面'
    },
    //编辑页面
    async handleEditCustomPage(row) {
      const { is_open, ...data } = row
      this.customPageDialog = true
      this.customPageTitle = '编辑页面'
      this.customPage = { is_open: is_open == 1, ...data }
      this.customPage.platform = this.platform
    },
    //删除页面
    async handleDelCustomPage({ id }) {
      this.$confirm('确认删除当前页面吗？').then((_) => {
        delCustomPage(id).then((res) => {
          this.$message({ type: 'success', message: '操作成功！' })
          this.$refs.finder.refresh(true)
        })
      })
    },
    //装修页面
    handleDecorationPage(row) {
      const { id, platform } = row
      if (platform === 'byte_mini') {
        this.$router.push({
          path: this.matchInternalRoute('wxapp/manage/decorate'),
          query: {
            scene: 1008,
            template_id: id,
            template_douyin: 1,
            iscustompage: 1
          }
        })
      } else {
        this.$router.push({
          path: this.matchInternalRoute('wxapp/manage/decorate'),
          query: {
            scene: 1005,
            template_id: id,
            iscustompage: 1
          }
        })
      }
    },
    //审核记录
    handleLogDialog(row) {
      useDialog(null, {
        title: `审核记录`,
        id: row[0].id,
        component: () => import('./comps/examineLog.vue'),
        actions: [
          {
            label: '关闭',
            key: 'close',
            type: 'default',
            size: 'medium'
          }
        ]
      })
    },
    //提交审核弹窗
    handleExamineDialog(row) {
      this.examineForm = {
        is_auto_replace: 1,
        cron_replace_time: '',
        telId: row.id,
      }
      if(row.is_open==1){
        this.examineTitle='提交审核'
        this.examineDialog = true
      }else{
        this.handleExamineFormSubmit()
      }
    },
    //提交审核/更新时间
    async handleExamineFormSubmit() {
      const now = new Date();
      const halfHourLater = new Date(now.getTime());
      const timestap = Date.parse(halfHourLater)
      const { is_auto_replace, cron_replace_time, log_id, telId } = this.examineForm
      if (is_auto_replace == 2 && cron_replace_time < timestap) {
        this.$message.error('定时更新时间不能小于当前时间')
        this.$set(this.examineForm, 'cron_replace_time', '')
        return
      }
      if (log_id) {
        await this.$api.template.updateReplaceTime({
          log_id,
          is_auto_replace,
          cron_replace_time: cron_replace_time / 1000,
        })
        this.$message({
          type: 'success',
          message: '更新时间成功!'
        })
        this.$nextTick(() => {
          this.examineDialog = false
          this.$refs.finder.refresh()
        })
        return
      }
      this.$confirm('确定提交审核？审核期间将不能编辑该模块。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await this.$api.template.submitExamineTemplate({
            template_id: telId,
            is_auto_replace,
            cron_replace_time: cron_replace_time / 1000,
            template_type: 'custompage'
          })
          this.$message({
            type: 'success',
            message: '提交审核成功!'
          })
          this.$nextTick(() => {
            this.examineDialog = false
            this.$refs.finder.refresh()
          })
        })
    },
    // 变更更新时间
    async handleChangeExamineTimeDialog(row) {
      this.examineForm = {
        is_auto_replace: 1,
        cron_replace_time: '',
        log_id: row.log_id
      }
      this.examineDialog = true
      this.examineTitle = '变更更新时间'
    },
    //撤销审核
    async handleRevertExamineDialog(row) {
      this.$confirm('确定撤销审核？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const res = await this.$api.template.getcustomTemrExamineList({
            template_id: row.id,
            pageSize: 1,
            page: 1
          })
          const logId = res.list[0].id
          await this.$api.template.revertExamineTemplate({ log_id: logId })
          this.$message({
            type: 'success',
            message: '撤销审核成功!'
          })
          this.$nextTick(() => {
            this.$refs.finder.refresh()
          })
        })
        .catch(() => { })
    },
    //审核审批
    closeExamineDialog() {
      this.examineDialogVisible = false
      this.$refs.finder.refresh()
    },
    //打开审核
    handleExamineItemDialog(item) {
      this.selectTemplate = item
      this.examineDialogVisible = true
    },
  },
  computed: {
    ...mapGetters(['wheight', 'template_name', 'login_type', 'admin_type']),
    setting(){
      return createSetting({
        search: [{ name: '页面ID', key: 'id' }, { name: '页面名称', key: 'page_name' }],
        columns: this.login_type === 'admin' || this.login_type === 'staff'?[
          { name: '页面ID', key: 'id',minWidth: 80 },
          { name: '页面名称', key: 'page_name', minWidth: 220 },
          {
            name: '审核状态',minWidth: 100,
            key: 'examine_status',
            render: (h, { row }) =>
              h(
                'ElTag',
                {
                  props: {
                    type: EXAMINE_STATUS_TYPE[row.examine_status]
                  }
                },
                EXAMINE_STATUS[row.examine_status]
              )
          },
          {
            name: '是否启用',minWidth: 100,
            key: 'is_open',
            render: (h, { row }) =>
              h(
                'ElTag',
                {
                  props: {
                    type: row.is_open == '0' ? 'warning' : 'success'
                  }
                },
                row.is_open == '0' ? '禁用' : '启用'
              )
          },
        ]:[
          { name: '页面ID', key: 'id', minWidth: 80},
          { name: '页面名称', key: 'page_name', minWidth: 220 },
          {
            name: '审核状态', minWidth: 100,
            key: 'examine_status',
            render: (h, { row }) =>
              h(
                'ElTag',
                {
                  props: {
                    type: EXAMINE_STATUS_TYPE[row.examine_status]
                  }
                },
                EXAMINE_STATUS[row.examine_status]
              )
          },
          { name: '模版更新状态', key: 'replace_status', minWidth: 110},
          { name: '最后更新时间', key: 'last_updated_time', minWidth: 160 },
          {
            name: '是否启用',
            key: 'is_open', minWidth: 100,
            render: (h, { row }) =>
              h(
                'ElTag',
                {
                  props: {
                    type: row.is_open == '0' ? 'warning' : 'success'
                  }
                },
                row.is_open == '0' ? '禁用' : '启用'
              )
          },
        ],
        actions: [
          {
            name: '新增页面',
            key: 'add',
            slot: 'header',
            type: 'button',
            buttonType: 'primary',
            size: 'small',
            visible: (row) => {
              return this.login_type == 'disti'
            },
            action: {
              handler: () => {
                this.handleAddCustomPage()
              }
            }
          },
          {
            name: '编辑',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return this.login_type == 'disti'
            },
            action: {
              handler: (row) => {
                this.handleEditCustomPage(row[0])
              }
            }
          },
          {
            name: '删除',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            action: {
              handler: (row) => {
                this.handleDelCustomPage(row[0])
              }
            }
          }, {
            name: '页面装修',
            key: 'detail',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return this.login_type == 'disti'
            },
            action: {
              handler: (row) => {
                this.handleDecorationPage(row[0])
              }
            }
          },
          {
            name: '审核记录',
            key: 'edit',
            type: 'button',
            buttonType: 'text', visible: (row) => {
              return this.login_type == 'disti'
            },
            action: {
              handler: (row) => {
                this.handleLogDialog(row)
              }
            }
          },
          {
            name: '变更更新时间',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.update_cron_replace_time && this.login_type == 'disti'
            },
            action: {
              handler: (row) => {
                this.handleChangeExamineTimeDialog(row[0])
              }
            }
          },
          {
            name: '提交审核',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.examine_status == 1 && this.login_type == 'disti'
            },
            action: {
              handler: (row) => {
                this.handleExamineDialog(row[0])
              }
            }
          },
          {
            name: '撤销审核',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.examine_status == 2 && this.login_type == 'disti'
            },
            action: {
              handler: (row) => {
                this.handleRevertExamineDialog(row[0])
              }
            }
          },
          {
            name: '审核',
            key: 'edit',
            type: 'button',
            buttonType: 'text',
            visible: (row) => {
              return row.examine_status == 2 && (this.login_type === 'admin' || this.login_type === 'staff')
            },
            action: {
              handler: (row) => {
                this.handleExamineItemDialog(row[0])
              }
            }
          },
        ]
      })
    }
  },
  watch: {
    admin_type: {
      handler(newVal, oldVal) {
        if (newVal !== 'order') {
          this.platform = 'wxapp'
          this.url = `wxa/customizepage/list`
        } else {
          this.platform = 'byte_mini'
          this.url = `byte_mini/customizepage/list`
        }
      },
      immediate: true,
    }
  }
}
</script>

<style lang="scss" scoped></style>