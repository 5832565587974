<template>
  <div>
    <div>
      <el-row>
        <el-col :span="4">
          <el-input placeholder="请输入热词搜索" v-model="params.content" />
        </el-col>
        <el-col :span="4">
          <el-button type="primary" size="medium" @click="beforeSearch">搜索</el-button>
          <el-button type="primary" size="medium" @click="addAction">添加热词</el-button>
        </el-col>
      </el-row>
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="全部" name="all"></el-tab-pane>
        <el-tab-pane label="展示" name="true"></el-tab-pane>
        <el-tab-pane label="不展示" name="false"></el-tab-pane>
        <el-table :data="hotWordsList" :height="wheight-200" v-loading="loading">
          <el-table-column prop="id" label="热词ID" width="100"></el-table-column>
          <el-table-column prop="content" label="热词名称" min-width="180"></el-table-column>
          <el-table-column prop="path" label="路径" min-width="150">
            <template slot-scope="scope">
              {{ JSON.parse(scope.row.path) ? getLabelName(JSON.parse(scope.row.path)) : '-' }}
            </template>
          </el-table-column>
          <el-table-column label="默认状态" min-width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.is_default"
                @change="handleIsShowChange(scope.row, 'is_default')"
              />
            </template>
          </el-table-column>
          <el-table-column label="展示状态" min-width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.is_show"
                @change="handleIsShowChange(scope.row, 'is_show')"
              />
            </template>
          </el-table-column>
          <el-table-column label="展示排序" min-width="100">
            <template slot-scope="scope">
              <el-input v-model="scope.row.sort" @change="handleIsShowChange(scope.row, 'sort' )" ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="联想搜索词" min-width="200">
            <template slot-scope="scope">
              <span v-for="(item, index) in scope.row.rel" :key="index">{{ item.content + '、' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="text" @click="editAction(scope.row)">编辑</el-button>
              <el-button type="text"  @click="delAction(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="total_count > params.pageSize" class="content-center content-top-padded">
          <el-pagination
            background
            layout="total, sizes, prev, pager, next"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :page-sizes="[10,20,50]"
            :current-page.sync="params.page"
            :total="total_count" :page-size="params.pageSize">
          </el-pagination>
        </div>
      </el-tabs>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="40%" @close="closeChange" :close-on-click-modal="false">
      <el-form :model="dialogForm" :rules="dialogRules" ref="dialogForm" label-width="100px">
        <el-form-item label="热词名称" prop="content">
          <el-input v-model="dialogForm.content" maxlength="20" placeholder="请输入热词名称" show-word-limit style="width: 55%;"></el-input>
        </el-form-item>
        <el-form-item label="搜索联想词">
          <div class="associational_word">
            <el-tag
              v-for="tags in associational_word"
              :key="tags"
              closable
              style="margin-right: 10px;"
              @close="delTagsChange(tags)"
            >
              {{tags}}
            </el-tag>
            <el-input
              v-model="inputValue"
              style="width: 25%;"
              placeholder="请输入联想词"
            >
            </el-input>
            <i slot="suffix" class="el-input__icon el-icon-circle-plus-outline" style="color:#1989fa;font-size: 18px;" @click="addHotWordChange"></i>
          </div>
        </el-form-item>
        <el-form-item label="路径设置" required>
          <el-tag @click="onPickChange">{{ getLabelName(this.localValue) }}</el-tag>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer content-center">
        <el-button @click="closeChange">取 消</el-button>
        <el-button type="primary" @click="addChange('dialogForm')">确 定</el-button>
      </div>
    </el-dialog>
    <router-view></router-view>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { getPreferential, seckillActivityUpdateStatus } from '@/api/promotions'
import { getKeywords, postKeywords, delKeywords, editKeywords } from '@/api/shopScreen'
import { getDistributorList } from '@/api/marketing'
import { LINK_PATH } from '@/consts'
export default {
  props: ['getStatus'],
  data () {
    return {
      activeName: 'all',
      hotWordsList: [],
      loading: false,
      total_count: 0,
      params: {
        page: 1,
        pageSize: 20,
        content: '',
      },
      dialogVisible: false,
      dialogTitle: '',
      dialogForm: {
        content: '',
        rel: []
      },
      dialogRules: {
        content: [
          { required: true, message: '请输入热词名称', trigger: 'blur' }
        ]
      },
      inputValue: '',
      localValue: {},
      associational_word: []
    }
  },
  computed: {
    ...mapGetters([
      'wheight',
      'wxapp_id',
      'template_name'
    ])
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name
      if (tab.name == 'false' || tab.name == 'true') {
        this.params.is_show = tab.name == 'true' ? true : tab.name == 'false' ? false : ''
      } else {
        delete this.params.is_show
      }
      this.params.page = 1
      this.getHotWordsList()
    },
    handleSizeChange (pageSize) {
      this.params.page = 1
      this.params.pageSize = pageSize
      this.getHotWordsList()
    },
    handleCurrentChange (page_num) {
      this.params.page = page_num
      this.getHotWordsList()
    },
    addAction () {
      this.dialogTitle = '热词新增'
      this.dialogVisible = true
    },
    editAction (row) {
      this.dialogTitle = '热词编辑'
      this.dialogVisible = true
      this.dialogForm = {
        id: row.id,
        content: row.content
      }
      this.localValue = JSON.parse(row.path) || {}
      this.associational_word = row.rel.map(item => item.content)
    },
    delAction (row) {
      console.log('del-row: ', row);
      let that = this
      this.$confirm('此操作将删除所选热词, 是否继续?', '提示', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        delKeywords(row.id).then((response) => {
          this.$message({
            message: '删除成功',
            type: 'success',
            duration: 2 * 1000
          });
          loading.close()
          that.getHotWordsList()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    beforeSearch() {
      this.params.page = 1
      this.getHotWordsList()
    },
    getHotWordsList () {
      this.loading = true
      getKeywords(this.params).then(response => {
        this.hotWordsList = response.data.data.list
        this.total_count = response.data.data.total_count
        this.loading = false
      })
    },
    handleIsShowChange (row, type)  {
      let param = {
        id: row.id,
        [type]: row[type],
      }
      editKeywords(param).then(response => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.getHotWordsList()
      })
    },
    editItemsSort (index, row) {
      // var reg = /(^[1-9]?$)/
      // if (row.items_arr.sort == '') {
      //   this.$message.error('不能为空');
      //     return;
      //   // callback(new Error('不能为空'))
      // } else if (!reg.test(row.items_arr.sort)) {
      //   this.$message.error('必须为1-9之间的数字');
      //     return;
      //   // callback(new Error('必须为1-9之间的数字'))
      // }

      // let param = {
      //       id: row.id,
      //       type: row.type,
      //       items: row.items_arr,
      //     }

      //   updatePreferential (row.id, param).then(response => {
      //   const that = this
      //   this.$message({
      //     message: '修改成功',
      //     type: 'success',
      //     duration: 3 * 1000,
      //     onClose () {
      //       // this.closeDialogAction1()
      //       that.closeDialog()
      //       that.beforeSearch()
      //       // this.$router.go(-1)
      //     }
      //   })
      // })
    },
    addHotWordChange () {
      if (!this.inputValue) return
      console.log(this.dialogForm)
      const find = this.associational_word.find(item => item === this.inputValue)
      console.log(this.inputValue, find)
      if (find) {
        this.$message.error('不能重复添加')
        return
      }
      this.associational_word.push(this.inputValue)
      this.inputValue = ''
    },
    addChange (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          if (!this.localValue.id) {
            this.$message.error('请设置路径')
            return
          }
          if (this.inputValue) {
            this.associational_word.push(this.inputValue)
          }
          const params = {
            ...this.dialogForm,
            path: JSON.stringify(this.localValue),
            rel: this.associational_word
          }
          // 如果没有id，则表示为新增
          if (!this.dialogForm.id) {
            postKeywords(params).then((res) => {
              this.$message({ type: 'success', message: '操作成功' })
              this.params.page = 1
              this.getHotWordsList()
            })
          } else {
            postKeywords(params).then((res) => {
              this.$message({ type: 'success', message: '操作成功' })
              this.getHotWordsList()
            })
          }
          this.closeChange()
          // addPagesTemplate(this.dialogForm).then(res => {
          //   this.getList()
          //   this.resetForm(formName)
          // })
        }
      })
    },
    closeChange () {
      this.inputValue = ''
      this.dialogTitle = ''
      this.dialogForm = {}
      this.dialogVisible = false
      this.localValue = {}
      this.associational_word = []
    },
    async onPickChange () {
      const { linkPage, id } = this.localValue
      const res = await this.$picker.path({
        data: id,
        tab: linkPage,
        multiple: false,
        wxapp_id: this.wxapp_id,
        template_name: this.template_name,
        isDouyin: false,
        isHotWords: true
      })
      console.log(res, '------')
      this.localValue = {
        ...res,
        linkType: 0,
        linkUrl: '',
        video_cover: '',
        CopyUrl: "",
        CopyTitle: "",
        autoplay: false,
        hotzoneType: 'link'
      }
    },
    getLabelName(value) {
      console.log(value, 'value')
      const { linkPage, title, id } = value
      if (linkPage) {
        return `${LINK_PATH[linkPage]}：${id ? '[' + id + ']' : ''} ${title}`
      } else {
        return '选择路径'
      }
    },
    delTagsChange (tags) {
      console.log(tags)
      const index = this.associational_word.findIndex(item => item === tags)
      this.associational_word.splice(index, 1)
    }
  },
  mounted () {
    this.getHotWordsList()
  },
  watch: {
    getStatus (val) {
      if (val) {
        this.getHotWordsList()
      }
    }
  }
}
</script>
<style scoped lang="scss">
</style>
