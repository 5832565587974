<template>
  <div class="container question-container">
    <el-form ref="form" :rules="rules" :model="form" label-width="110px" style="height:100%">
      <el-card shadow="never" class="step-card">
        <div v-for="(item, index) in step_list" :key="index" class="step-container">
          <div :class="step_active >= index + 1 ? 'step-no active border-active' : 'step-no'">{{ step_active <= index + 1 ? index + 1 : '√' }}</div>
          <div class="step-content">
            <div :class="step_active >= index + 1 ? 'title active' : 'title'">{{ item.title }}</div>
            <div>{{ item.desc }}</div>
          </div>
          <div class="arrow-right"></div>
        </div>
      </el-card>
      <el-card shadow="never" v-if="step_active == 1" class="first-question-card">
        <el-form-item label="问卷标题" prop="tpl_name">
          <el-input :maxlength="inputValue.tpl_name_max" placeholder="问卷标题" show-word-limit :rows="2" v-model="form.tpl_name" type="textarea" class="w700" />
        </el-form-item>
        <el-form-item label="问卷描述" prop="tpl_desc">
          <el-input :maxlength="inputValue.tpl_desc_max" placeholder="问卷描述" show-word-limit :rows="2" v-model="form.tpl_desc" type="textarea" class="w700" />
        </el-form-item>
        <el-form-item label="问卷交互形式" prop="show_type">
          <el-radio-group v-model="form.show_type" size="small">
            <el-radio-button :label="1">纵向滚动浏览</el-radio-button>
            <el-radio-button :label="2">横向切屏浏览</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-card>
      <el-card shadow="never" v-else class="second-question-card">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>一级问卷项</span>
            <el-button style="float: right;" size="mini" type="primary" @click="addFirstOption(1)">添加问卷项</el-button>
          </div>
          <div v-for="(pitem, pindex) in question_first_list" :class="firstSelectId == pitem.id ? 'question_list select' : 'question_list'" :key="pitem.id">
            <CompOption
              :data="pitem"
              :curlevel="1"
              :pindex="pitem.id"
              :select="firstSelectOption"
              @onChange="({ value, type }) => onFirstSelectChange({ value, type, pitem, pindex, level: 1 })"
              @onScoped="({value, type}) => onFirstScopedChange({ value, type, pitem, level: 1, pindex })"
            />
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>二级问卷项</span>
            <el-button style="float: right;" size="mini" type="primary" @click="addFirstOption(2)">添加问卷项</el-button>
          </div>
          <div v-for="(pitem, pindex) in question_second_list" :class="secondSelectId == pitem.id ? 'question_list select' : 'question_list'" :key="pitem.id">
            <CompOption
              :data="pitem"
              :curlevel="2"
              :pindex="pitem.id"
              :sid="firstSelectOption"
              :select="secondSelectOption"
              @onChange="({ value, type }) => onSecondSelectChange({ value, type, pitem, pindex, level: 2 })"
              @onScoped="({value, type}) => onFirstScopedChange({ value, type, pitem, level: 2, pindex })"
            />
          </div>
        </el-card>
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>三级问卷项</span>
            <el-button style="float: right;" size="mini" type="primary" @click="addFirstOption(3)">添加问卷项</el-button>
          </div>
          <div v-for="(pitem, pindex) in question_third_list" :class="thirdSelectId == pitem.id ? 'question_list select' : 'question_list'" :key="pitem.id">
            <CompOption
              :data="pitem"
              :curlevel="3"
              :pindex="pitem.id"
              :sid="secondSelectOption"
              :select="thirdSelectOption"
              @onChange="({ value, type }) => onThirdSelectChange({ value, type, pitem, pindex, level: 3 })"
              @onScoped="({value, type}) => onFirstScopedChange({ value, type, pitem, level: 3, pindex })"
            />
          </div>
        </el-card>
      </el-card>
      <el-button type="primary" @click="onStepClick('next', 'form')" plain v-if="step_active == 1">完成，进入下一步</el-button>
      <el-button type="primary" @click="onStepClick('back')" plain v-if="step_active == 2">返回上一步</el-button>
      <el-button type="primary" @click="onStepClick('sumbit')" v-if="step_active == 2">保存</el-button>
    </el-form>
    <CompFirstDialog
      :curlevel="dialogLevel"
      :visible="dialogVisible"
      :title="dialogTitle"
      :form="dialogForm"
      :tplId="form.id"
      @saveChange="saveChange"
      @closeChange="closeChange"
    />
  </div>
</template>

<script>
import { setQuestionSort, delQuestionOption, updateQuestionnairetpl, getQuestionnairetplDetail, getQuestionnaireLevelQuestionList } from '@/api/wxa'

// 组件
import SkuSelector from '@/components/function/skuSelector'
import couponPicker from '@/components/coupon_gift_picker'
import GoodsSelect from '@/components/goodsSelect'
import CustomizeSelect from '@/components/function/customizeSelect'

import CompFirstDialog from './comp/comp-first-dialog.vue'
import CompOption from './comp/comp-option.vue'

import { mapGetters } from "vuex";

export default {
  inject: ['refresh'],
  components: {
    SkuSelector,
    couponPicker,
    GoodsSelect,
    CustomizeSelect,
    CompFirstDialog,
    CompOption
  },
  data () {
    var tplNameChecked = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('问卷标题不能为空'))
      } else if (this.inputValue.title_length > this.inputValue.title_max) {
        callback(new Error('问卷标题长度不超过' + this.inputValue.title_max + '个汉字或' + (this.inputValue.title_max * 2) + '个英文字母'))
      } else {
        callback()
      }
    }
    var tplDescChecked = (rule, value, callback) => {
      if (value == '') {
        callback(new Error('问卷描述不能为空'))
      } else if (this.inputValue.title_length > this.inputValue.title_max) {
        callback(new Error('问卷描述长度不超过' + this.inputValue.title_max + '个汉字或' + (this.inputValue.title_max * 2) + '个英文字母'))
      } else {
        callback()
      }
    }
    return {
      step_list: [
        { title: '第1步', desc: '完善基础信息' },
        { title: '第2步', desc: '配置问卷内容' },
      ],
      inputValue: {
        tpl_name_max: 128,
        tpl_desc_max: 128
      },
      rules: {
        tpl_name: [
          { required: true, validator: tplNameChecked, trigger: 'blur' },
        ],
        show_type: [
          { required: true, trigger: 'blur', message: '请选择问卷交互形式' },
        ],
        // tpl_desc: [
        //   { required: true, validator: tplDescChecked, trigger: 'blur' },
        // ]
      },
      form: {
        id: '',
        tpl_name: '',
        tpl_desc: '',
        show_type: 1
      },
      step_active: 1,
      question_first_list: [],
      question_second_list: [],
      question_third_list: [],
      dialogVisible: false,
      dialogLevel: 0,
      dialogTitle: '',
      dialogForm: {},
      firstSelectId: null,
      firstSelectOption: null,
      secondSelectId: null,
      secondSelectOption: null,
      thirdSelectId: null,
      thirdSelectOption: null
    }
  },
  computed: {
    ...mapGetters(["wheight"])
  },
  methods: {
    async getQuestionDetail () {
      const res = await getQuestionnairetplDetail(this.form.id)
      const response = res.data.data
      const { one = [], two = [], three = [] } = response.questionnaire_list || {}
      let data = {
        id: response.id,
        tpl_name: response.tpl_name,
        scene: response.scene,
        questionnaire_list: response.questionnaire_list,
        tpl_desc: response.tpl_desc,
        show_type: response.show_type
      }
      this.question_first_list = one
      this.question_second_list = two
      this.question_third_list = three
      this.clearChange()
      Object.assign(this.form, data)
    },
    async getQuestionLevelList (params) {
      console.log(params, 'getQuestionLevel')
      let data = { tpl_id: this.form.id, question_level: params.level }
      if (params.type == 'parent') {
        data['parent_list_id'] = params.value
      } else if (params.type == 'son') {
        if (!params.value) { // 如果子选项没有选中值，则传上一级父问卷的id 用parent_list_id字段
          data['parent_list_id'] = params.pitem.id
        } else {
          data['parent_answer_id'] = params.value
        }
      }
      const res = await getQuestionnaireLevelQuestionList(data)
      if (params.level == 2) {
        this.question_second_list = res.data.data
      } else if (params.level == 3) {
        this.question_third_list = res.data.data
      }
    },
    async onStepClick (type, formName) {
      if (type == 'back') {
        this.step_active = 1
      } else if (type == 'next') {
        this.$refs[formName].validate(valid => {
          if (valid) {
            this.step_active = 2
          }
        })
      } else if (type == 'sumbit') {
        const params = {
          tpl_desc: this.form.tpl_desc,
          tpl_name: this.form.tpl_name,
          id: this.form.id,
          show_type: this.form.show_type
        }
        await updateQuestionnairetpl(params).then(res => {
          setTimeout(() => {
            this.$router.go(-1)
          }, 500)
        })
      }
    },
    async saveChange (form) {
      console.log(form, this.dialogLevel, this.form, 'saveChange')
      let params = {
        ...this.form,
        questionnaire_list: [form],
        question_level: this.dialogLevel,
        scene: 5,
        increase: true
      }
      if (!this.form.id) delete params.id
      console.log(params)
      await updateQuestionnairetpl(params).then(res => {
        this.form.id = res.data.data.id
        const id = this.form.id
        console.log(res)
        this.$message({
          message: id ? '保存成功' : '编辑成功',
          type: 'success',
        })
        this.closeChange()
        this.getQuestionDetail()
      }).catch(error => {
        console.log(error)
      })
    },
    closeChange () {
      this.dialogForm = {}
      this.dialogLevel = null
      this.dialogTitle = ''
      this.dialogVisible = false
    },
    addFirstOption (level) {
      this.dialogTitle = level == 1 ? '添加一级问卷项' : level == 2 ? '添加二级问卷项' : level == 3 ? '添加三级问卷项' : ''
      this.dialogLevel = level
      this.dialogVisible = true
    },
    async onFirstScopedChange (params) { // type:edit delete sort
      console.log(params, 'onFirstScopedChange')
      const { type, pitem, level, value, pindex } = params
      const label = level == 1 ? 'firstSelectId': level == 2 ? 'secondSelectId' : level == 3 ? 'thirdSelectId' : ''
      if (level == 1) {
        this[label] = pitem.id // 选中父元素
      }
      if (type == 'edit') {
        this.dialogTitle = level == 1 ? '编辑一级问卷项' : level == 2 ? '编辑二级问卷项' : level == 3 ? '编辑三级问卷项' : ''
        this.dialogLevel = level
        this.dialogForm = JSON.parse(JSON.stringify(pitem))
        this.dialogVisible = true
      } else if (type == 'delete') {
        this.$confirm('是否要删除该问卷?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await delQuestionOption({ id: pitem.id })
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        if (res.data.data.success) this.getQuestionDetail()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '删除失败'
          })
        })
      } else if (type == 'sort') {
        const res = await setQuestionSort({ sort: value, id: pitem.id })
        if (res.data.data.success) this.getQuestionDetail()
      }
    },
    onFirstSelectChange (params) {
      const { value, type, pitem, pindex } = params
      console.log(params, 'onFirstSelectChange')
      if (!value || value != this.firstSelectOption) {
        this.secondSelectId = null
        this.secondSelectOption = null
        this.thirdSelectId = null
        this.thirdSelectOption = null
      }
      if (type == 'parent') {
        this.firstSelectOption = null // 点击父元素，取消子元素选中的值
        if (this.firstSelectId == pitem.id) { // 选中值和传入值相等时，取消选中
          this.firstSelectId = null
          this.getQuestionLevelList({...params, type: '', level: 2}) // 父问卷取消选中时，获取二级，不需要传参数
          this.getQuestionLevelList({...params, type: '', level: 3}) // 同时获取三级，不需要传参数
          return
        }
      } else if (type == 'son') {
        this.firstSelectOption = value // 点击子元素时，更改子组件选中的值
      }
      this.firstSelectId = pitem.id // 选中父元素
      this.getQuestionLevelList({...params, level: 2}) // 一级父/子问卷中时，获取二级问卷，父传parent_list_id 子传parent_list_id
      this.getQuestionLevelList({...params, type: '', level: 3}) // 同时获取三级全部问卷，不需要传参数
    },
    onSecondSelectChange (params) {
      const { value, type, pitem, pindex } = params
      console.log(params, 'onSecondSelectChange')
      if (!value || value != this.secondSelectOption) {
        this.thirdSelectId = null
        this.thirdSelectOption = null
      }
      if (type == 'parent') {
        this.secondSelectOption = null // 点击父元素，取消子元素选中的值
        if (this.secondSelectId == pitem.id) { // 选中值和传入值相等时，取消选中
          this.secondSelectId = null
          this.getQuestionLevelList({...params, type: '', level: 3}) // 二级父问卷取消时，重新获取三级全部问卷，不需要传参数
          return
        }
      } else if (type == 'son') {
        this.secondSelectOption = value // 点击子元素时，更改子组件选中的值
      }
      this.secondSelectId = pitem.id // 选中父元素
      this.getQuestionLevelList({ ...params, level: 3 }) // 二级父/子问卷中时，获取三级问卷，父传parent_list_id 子传parent_list_id
    },
    onThirdSelectChange (params) {
      const { value, type, pitem, pindex } = params
      console.log(params, 'onThirdSelectChange')
      if (!value || value != this.thirdSelectOption) {
        this.thirdSelectId = null
        this.thirdSelectOption = null
      }
      if (type == 'parent') {
        this.thirdSelectOption = null // 点击父元素，取消子元素选中的值
        if (this.thirdSelectId == pitem.id) { // 选中值和传入值相等时，取消选中
          this.thirdSelectId = null
          return
        }
      } else if (type == 'son') {
        this.thirdSelectOption = value // 点击子元素时，更改子组件选中的值
      }
      this.thirdSelectId = pitem.id // 选中父元素
    },
    clearChange () {
      this.firstSelectId = null
      this.firstSelectOption = null
      this.secondSelectId = null
      this.secondSelectOption = null
      this.thirdSelectId = null
      this.thirdSelectOption = null
    }
  },
  mounted () {
    const id = this.$route.query.id
    if (id) {
      this.form.id = id
      this.getQuestionDetail()
    } else {
    }
  }
}
</script>

<style lang="scss">
.question-container {
  .w700 {
    width: 700px;
  }
  .step-card {
    .el-card__body {
      display: flex;
      width: 100%;
      padding: 0px;
    }
  }
  .step-container {
    display: flex;
    align-items: center;
    margin-left: 50px;
    padding: 20px;
    padding-right: 80px;
    position: relative;
    color: #AEAEAE;
    &:first-child {
      margin-left: 1%;
    }
  }
  .step-no {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border: 2px solid #797979;
    color: #797979;
  }
  .step-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15px;
    .title {
      font-size: 16px;
      font-weight: 400;
      color: #797979;
    }
  }
  .active {
    color: #5B8FF9 !important;
  }
  .border-active {
    border: 2px solid #5B8FF9 !important;
  }
  .arrow-right {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-top: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;
    transform: rotate(45deg);//控制箭头的旋转方向，为顺时针旋转
    position: absolute;
    right: 0;
  }
  .first-question-card, .second-question-card {
    min-height: 65%;
    .el-card__body {
      width: 100%;
    }
  }
  .second-question-card {
    overflow-x: auto;
    .el-card__body {
      display: flex;
      // align-items: center;
      // justify-content: space-around;
    }
  }
  .box-card {
    max-width: 32%;
    margin-right: 10px;
    .el-card__body {
      display: flex;
      flex-direction: column;
      min-height: 400px;
      max-height: 800px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 5px;
        height: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 8px;
        background-color: #a8a6ad;

      }
    }
    &:last-child {
      margin-bottom: 20px;
      margin-right: 0px;
    }
  }
  .question_list {
    border: 1px solid #ebeef5;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 20px;
  }
  .question_list.select {
    border: 1px solid #409EFF;

  }
}
</style>
